<template>
  <div>
    <top-nav-bar></top-nav-bar>
    <div class="page position-absolute">
      <section class="container">
        <div class="my-2" v-html="markDown"></div>
      </section>
    </div>
  </div>
</template>

<script>
import marked from "marked";
import privacyText from "./privacy.md";
export default {
  inject: ["activePage"],
  data() {
    return {
      data: "test",
    };
  },
  computed: {
    markDown() {
      return marked(privacyText);
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routePrivacyPage;
  },
};
</script>

<style scoped></style>
